'use client';

import React, { useState, useCallback, useRef, ChangeEvent, useEffect } from 'react';
import { Button } from '@/components/Button';
import { Input } from '@/components/Forms/Input';
import { Modal } from '@/components/Modal';
import { maskPhone } from '@/utils/mask';
import { debounce } from '@/utils/debounce';
import * as Popover from '@radix-ui/react-popover';
import { Service } from '@/types';
import { Autocomplete } from '@/components/Forms/Autocomplete';

const content = 'mt-1 w-[--radix-popper-anchor-width] z-[99] text-sm md:text-base mt-2 outline-0 rounded-md bg-[white] shadow-[0px_0px_7px_0px_hsla(206,22%,7%,.25),0_10px_20px_-15px_hsla(206,22%,7%,.2)] will-change-[transform,opacity] data-[state=open]:data-[side=top]:animate-slideDownAndFade data-[state=open]:data-[side=right]:animate-slideLeftAndFade data-[state=open]:data-[side=bottom]:animate-slideUpAndFade data-[state=open]:data-[side=left]:animate-slideRightAndFade';

const contentPopover = 'z-[99] text-sm md:text-base mt-2 outline-0 rounded-md bg-[white] shadow-[0px_0px_7px_0px_hsla(206,22%,7%,.25),0_10px_20px_-15px_hsla(206,22%,7%,.2)] will-change-[transform,opacity] data-[state=open]:data-[side=top]:animate-slideDownAndFade data-[state=open]:data-[side=right]:animate-slideLeftAndFade data-[state=open]:data-[side=bottom]:animate-slideUpAndFade data-[state=open]:data-[side=left]:animate-slideRightAndFade';

export function Form() {
  const [showModal, setShowModal] = useState(false);
  const [sended, setSended] = useState(false);
  const [whatsapp, setWhatsapp] = useState('');
  const [hasCustomSite, setHasCustomSite] = useState('');
  const [hasGMN, setHasGMN] = useState('');
  const [optionsCity, setOptionsCity] = useState([]);
  const [openCity, setOpenCity] = useState(false);
  const [data, setData] = useState<any>([]);
  const cidade = useRef<HTMLInputElement>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [titleRequest, settitleRequest] = useState<string>('');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [titlesResponse, setTitlesResponse] = useState<Array<Service> | undefined>();

  // const handleFlagCustomSite = (event: any) => {
  //   setHasCustomSite(event.target.value);
  // };

  // const handleFlagGMN = (event: any) => {
  //   setHasGMN(event.target.value);
  // };


  const handleInputChange = useCallback(
    ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      settitleRequest(value || '');
    },
    [],
  );

  const handlefocus = (e: any): void => {
    e.preventDefault();
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleServices = async () => {
    const params = new URLSearchParams({
      segmento: titleRequest,
    });

    const response = await fetch(`/api/search-gf-titles?${params}`).then((response) =>
      response.json(),
    );

    if (response?.data?.ArrayServiceSearch) {
      setTitlesResponse(response.data.ArrayServiceSearch);
      setIsOpen(true);
    } else {
      setTitlesResponse([]);
    }
  };

  useEffect(() => {
    titleRequest.length >= 3 ? handleServices() : setTitlesResponse(undefined);
  }, [titleRequest]);

  const handleClick = (navigator: { term: string, Url: string }) => {
    const { term, Url } = navigator;
    setData({ ...data, ['Business']: Url });
    settitleRequest(term);
    setIsOpen(false);
  };

  const handleInput = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => {
    if (name === 'Phone') {
      setWhatsapp(maskPhone(value));
    }

    setData({ ...data, [name]: value });
  };

  const handleClickCity = (value: any) => {
    setData({ ...data, ['City']: value.ID });
    if (cidade.current)
      cidade.current.value = value.UFName;
    setOpenCity(false);
  };

  const handleCitySearch = debounce(async ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    if (!value) return;

    try {
      const cities = await getCity(value);
      setOptionsCity(cities);
      setOpenCity(true);
    } catch (e) {
      console.log(e);
    }
  }, 300);

  const getCity = useCallback(async (city: string): Promise<any> => {
    const { Cities } = await fetch(`/api/city?search=${city}`).then(response => response.json());
    return Cities;
  }, []);


  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSended(true);
    try {
      data.hasGMN = hasGMN;
      data.hasCustomSite = hasCustomSite;

      if (data.Phone.length < 10) {
        alert('Por favor, preencha o campo WhatsApp');
        setSended(false);
        return;
      }

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ data }),
      };
      if (await fetch('/api/sendLead', requestOptions)) {
        setShowModal(true);
      }
    } catch (error) {
      alert('Erro ao se cadastrar');
      setSended(false);
      console.log(error);
    }
  };

  return (
    <>
      <div className='cadastrese-setup md:py-5' id='cadastre-se'>
        <span className='mb-1 text-center text-2xl md:text-xl'>
          Você é prestador de serviço? <b>Cadastre-se!</b>
        </span>
        <form
          onSubmit={handleSubmit}
          className='flex w-full flex-col items-center'
        >
          <div className='flex flex-col xl:flex-row'>
            <Input
              className='text-primary-important responsive-input-cadastrese m-1'
              id='nome'
              type='text'
              required
              placeholder='Seu nome'
              name='Name'
              icon='mdi-account-outline'
              onInput={handleInput}
            />

            <Input
              className='text-primary-important responsive-input-cadastrese m-1'
              id='businessName'
              type='text'
              required
              placeholder='Nome da sua empresa'
              name='BusinessName'
              icon='mdi-account-outline'
              onInput={handleInput}
            />

            <Input
              className='text-primary-important responsive-input-cadastrese m-1'
              id='whatsapp'
              type='text'
              required
              placeholder='Seu WhatsApp'
              name='Phone'
              icon='mdi-whatsapp'
              onInput={handleInput}
              value={whatsapp}
            />
          </div>
          <div className='flex flex-col xl:flex-row'>
            <Popover.Root open={openCity}>
              <Popover.Trigger />
              <Popover.Anchor asChild>
                <Input
                  className='text-primary-important responsive-input-cadastrese m-1'
                  id='cidade'
                  type='text'
                  required
                  placeholder='Sua Cidade'
                  name='City'
                  icon='mdi-map-marker'
                  onInput={handleCitySearch}
                  ref={cidade}
                />
              </Popover.Anchor>

              <Popover.Portal>
                <Popover.Content
                  onOpenAutoFocus={(e) => e.preventDefault()}
                  onInteractOutside={() => setOpenCity(false)}
                  className={content}>
                  {optionsCity &&
                    <>
                      <ul className='bg-[white]'>
                        {optionsCity.map((option: any) => {
                          return (
                            <li
                              onClick={() => handleClickCity(option)}
                              key={option.SlugName}
                              className={'cursor-pointer flex items-center px-[23px] h-[43px] border-b-[1px] border-bar hover:bg-bar'}
                            >
                              {option.Name}
                            </li>
                          );
                        })}
                      </ul>
                    </>
                  }
                </Popover.Content>
              </Popover.Portal>
            </Popover.Root>

            <Popover.Root open={isOpen}>
              <Popover.Trigger />
              <Popover.Anchor asChild>
                <Input
                  className='text-primary-important responsive-input-cadastrese m-1'
                  autoComplete={'off'}
                  ref={inputRef}
                  id='business'
                  icon='mdi-briefcase'
                  type={'text'}
                  placeholder={'Seu segmento'}
                  onInput={handleInputChange}
                  value={titleRequest}
                  required
                />
              </Popover.Anchor>

              <Popover.Portal>
                <Popover.Content onInteractOutside={() => setIsOpen(false)} className={contentPopover}
                                 onOpenAutoFocus={handlefocus}>
                  {titlesResponse &&
                    <Autocomplete callback={handleClick} services={titlesResponse} />
                  }
                </Popover.Content>
              </Popover.Portal>
            </Popover.Root>

            <Button
              type='submit'
              disabled={sended}
              className='responsive-input-cadastrese m-1 text-bg-secondary border-blue-700 rounded border text-center font-bold'
            >
              {sended ? 'Cadastrado' : 'Cadastrar'}
            </Button>
          </div>
        </form>

        <Modal
          isOpen={showModal}
          onClose={() => setShowModal(false)}
          haveSvg={true}
          className='modal-lead'
        >
          <p className='mb-4 text-2xl font-bold'>
            Solicitação de cadastro enviada!
          </p>
          <p>Em breve entraremos em contato com você!</p>
        </Modal>
      </div>
    </>
  );
}
